import React from "react";
import './downloadapphint.scss'

function DownloadAppHint() {
    return (
        <div className="download-app-hint">
            <div className="download-app-text">
                Please download the app for optimal usage of all features.
            </div>

            <div className="app-buttons">
                <a href={"https://apps.apple.com/nl/app/sweapinspectionapp/id1501674237"} target={"_blank"} rel="noopener noreferrer"><img className="app-button" src={require('../../resources/play-store-badge.png')} alt="App Store"/></a>
                <a href={"https://play.google.com/store/apps/details?id=com.zooma.impel"} target={"_blank"} rel="noopener noreferrer"><img className="app-button" src={require('../../resources/google-play-badge.png')} alt="Google Play"/></a>
            </div>
        </div>
    );
}

export default DownloadAppHint;